import { render, staticRenderFns } from "./addAddressPage.vue?vue&type=template&id=88f3e19c&scoped=true&"
import script from "./addAddressPage.vue?vue&type=script&lang=js&"
export * from "./addAddressPage.vue?vue&type=script&lang=js&"
import style0 from "./addAddressPage.vue?vue&type=style&index=0&id=88f3e19c&prod&scoped=true&lang=css&"
import style1 from "./addAddressPage.vue?vue&type=style&index=1&id=88f3e19c&prod&lang=css&"
import style2 from "./addAddressPage.vue?vue&type=style&index=2&id=88f3e19c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88f3e19c",
  null
  
)

export default component.exports